<template>
  <div class="page data-table-container">

    <div class="tools-bar">
      <el-button-group class="i">
        <el-button @click="load">刷新</el-button>
      </el-button-group>
      <el-select class="i" v-model="filter_type" placeholder="用户类型" @change="onChangeUserType" style="width: 140px">
        <el-option v-for="c in ['全部', '啦啦之星',  'VIP']" :key="c" :label="c" :value="c"/>
      </el-select>

      <div class="grow"></div>

      <el-input class="search" placeholder="搜索" v-model="inputSearch" clearable style="width: 300px"
                @keyup.enter.native="onSearch" @clear="load"
                suffix-icon="el-icon-search">
        <el-select slot="prepend" v-model="field_search" placeholder="请选择" style="width: 100px">
          <el-option v-for="c in ['UID', '昵称',  '手机号', '真实姓名']" :key="c" :label="c" :value="c"/>
        </el-select>
      </el-input>
    </div>

    <el-table class="table"
              :data="tableData"
              @row-click="onClickRow"
              v-loading="loading"
              fit highlight-current-row>
      <el-table-column align="center" label="UID" width="80" prop="id"/>
      <el-table-column label="用户" prop="realname">
        <template slot-scope="scope">
          {{ scope.row.realname || scope.row.nickname}}
          <el-tag style="margin-left: 10px" size="mini" type="danger" v-if="scope.row.is_vip">VIP</el-tag>
          <el-tag style="margin-left: 10px" size="mini" type="success" v-if="scope.row.is_vip_hf">花粉</el-tag>
          <el-tag style="margin-left: 10px" size="mini" v-if="scope.row.role==='啦啦之星'">啦啦之星</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="手机" prop="tel" width="140"/>
<!--      <el-table-column label="推广注册" width="70" prop="tg_num"/>-->
      <el-table-column label="积分" width="70" prop="point_num"/>
      <el-table-column label="VIP到期" width="130" prop="datetime_vip_expired"
                       :formatter="(row) => (row.datetime_vip_expired || '').slice(0,10)"/>
      <el-table-column label="注册时间" width="130" prop="datetime_created"
                       :formatter="(row) => (row.datetime_created || '').slice(0,10)"/>
      <el-table-column label="使用兑换码" width="120" prop="used_code"/>
      <!--<el-table-column label="上次登陆" width="160" prop="datetime_login_last"/>-->
    </el-table>

    <el-pagination class="pagination"
                   layout="total, prev, pager, next, jumper"
                   @current-change="load"
                   :current-page.sync="page"
                   :page-size="30"
                   :total="total"/>

  </div>

</template>

<script>
  import { export_json_to_excel } from '../plugins/Export2Excel'

  export default {
    components: {},
    data() {
      return {
        inputSearch: '',
        tableData: [],
        page: 1,
        total: 0,
        loading: false,
        userLoading: false,
        field_search: '',
        filter_type: '',
        vip_only: false,
        export_date_range: [],
      }
    },
    mounted() {
      this.load()
    },

    methods: {
      onChangeUserType(e) {
        this.page = 1
        this.load()
      },
      load() {
        this.loading = true
        this.$api.request('/user/list_with_page', {
          page: this.page,
          filter_type: this.filter_type
        }).then(data => {
          this.tableData = data.contains
          this.page = data.page
          this.total = data.total
          this.loading = false
          window.scrollTo(0, 0)
        })
      },
      onExport() {
        this.userLoading = true
        this.$api.request('/user/export', {date_range: this.export_date_range}).then(data => {
          this.userLoading = false
          export_json_to_excel({
            header: data.header, //表头 必填
            data: data.contains, //具体数据 必填
            filename: '用户数据导出', //非必填
            autoWidth: true, //非必填
            bookType: 'xlsx' //非必填
          })
        })
      },
      onClickRow({id}) {
        this.$router.push({name: 'user-info', query: {id}})
      },
      async onSearch(e) {
        if (!this.inputSearch) {
          this.page = 1
          return this.load()
        }
        this.tableData = await this.$api.request('/user/search', {
          key: this.inputSearch,
          field: this.field_search
        })
      }
    }
  }
</script>
